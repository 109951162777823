
















































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Yurt } from '../models/Yurt';

@Component
export default class PopupYurt extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public showPopup = false;
  public popup: L.Popup | null = null;
  public nameDisplay = '';
  public lat = 0;
  public long = 0;

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showYurtPopup') {
      this.onShowYurtPopup();
    }
  });

  get config() {
    return this.$store.state.config;
  }

  get yurtPermDetails() {
    return this.config.YurtLayer.popupPermDetails;
  }

  get sectionInfo() {
    return this.yurtPermDetails.sectionInfo;
  }

  get maximumAdultOccupants() {
    return this.yurtPermDetails.maximumAdultOccupants;
  }

  get internalHeatSource() {
    return this.yurtPermDetails.internalHeatSource;
  }

  get electricityAvailable() {
    return this.yurtPermDetails.electricityAvailable;
  }

  get lightSourceProvided() {
    return this.yurtPermDetails.lightSourceProvided;
  }

  get privacy() {
    return this.yurtPermDetails.privacy;
  }

  get generatorUsage() {
    return this.yurtPermDetails.generatorUsage;
  }

  get selectedYurt(): Yurt {
    return this.$store.state.layers.selectedYurt;
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get map(): L.Map {
    return this.$store.state.map;
  }

  public onShowYurtPopup() {
    const yurt = this.selectedYurt;

    this.showPopup = true;
    this.nameDisplay = yurt.Name;

    this.lat = yurt.Lat;
    this.long = yurt.Long;

    let lLatlng = null;
    lLatlng = L.latLng(yurt.Lat, yurt.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'yurtPopup',
      minWidth: 280,
      maxWidth: 280,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }
}

