import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import configStore from './modules/configStore';
import layerStore from './modules/layerStore';

import L from 'leaflet';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => ({
    splashPopupEnabled: state.splashPopupEnabled,
    measurementSystem: state.measurementSystem,
  }),
});

export interface State {
  splashPopupEnabled: boolean;
  measurementSystem: string;
  map: L.Map | null;
  zoom: number;
  centerLat: number;
  centerLon: number;
}

export default new Vuex.Store({
  state: {
    measurementSystem: 'imperial',
    splashPopupEnabled: true,
    map: null,
    zoom: -1,
    centerLat: -1,
    centerLon: -1,
    basemapLayer: null,
  } as State,
  getters: {},
  mutations: {
    SET_MAP(state: State, map: L.Map) {
      state.map = map;
    },
    SET_SPLASH_POPUP_ENABLED(state: State, isEnabled: boolean) {
      state.splashPopupEnabled = isEnabled;
    },
    SET_MEASUREMENT_SYSTEM(state: State, measurementSystem: string) {
      state.measurementSystem = measurementSystem;
    },
  },
  actions: {
    setMap(context, map: L.Map) {
      context.commit('SET_MAP', map);
    },
    setSplashPopupEnabled(context, isEnabled: boolean) {
      context.commit('SET_SPLASH_POPUP_ENABLED', isEnabled);
    },
    setMeasurementSystem(context, measurementSystem: string) {
      context.commit('SET_MEASUREMENT_SYSTEM', measurementSystem);
    },
    showLightBox(context) {
        //
    },
    showSplashNoCheckBox(context) {
      //
    },
  },
  plugins: [vuexLocal.plugin],
  modules: {
    config: configStore,
    layers: layerStore,
  },
});
