

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// @ts-ignore
import Plugin from '@quasar/quasar-ui-qmarkdown';
import '@quasar/quasar-ui-qmarkdown/dist/index.css';

// @ts-ignore
Vue.use(Plugin);

@Component
export default class SplashPopup extends Vue {
    public showPopup: boolean = true;
    public checkBoxVisible: boolean = true;
    public disableShowAgain = false;

    get SplashText() {
        return this.$store.state.config.splashText;
    }

    public unsubscribe = this.$store.subscribeAction((action, state) => {
        if (action.type === 'showSplashNoCheckBox') {
            this.onShowNoCheckbox();
        }
    });

    public onShowNoCheckbox() {
        this.showPopup = true;
        this.checkBoxVisible = false;
    }

    public onClose() {
        this.$store.dispatch('setSplashPopupEnabled', !this.disableShowAgain);
    }

    public mounted() {
        this.showPopup = this.$store.state.splashPopupEnabled;
    }
}

