import { render, staticRenderFns } from "./BasemapToggleWidget.vue?vue&type=template&id=1185c756&scoped=true&"
import script from "./BasemapToggleWidget.vue?vue&type=script&lang=ts&"
export * from "./BasemapToggleWidget.vue?vue&type=script&lang=ts&"
import style0 from "./BasemapToggleWidget.vue?vue&type=style&index=0&id=1185c756&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1185c756",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QAvatar,QImg,QTooltip});
