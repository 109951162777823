

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Trail } from '../models/Trail';

@Component
export default class PopupTrail extends Vue {
    public showPopup = false;
    public popup: L.Popup | null = null;

    public name: string = '';
    public displayDistance: string = '';

    public subscribe = this.$store.subscribeAction((action, state) => {
        if (action.type === 'showTrailPopup') {
            this.onShowTrailPopup();
        }
    });

    get map(): L.Map {
        return this.$store.state.map;
    }

    get config() {
        return this.$store.state.config;
    }

    get selectedTrail(): Trail {
        return this.$store.state.layers.selectedTrail;
    }

    public onShowTrailPopup() {
        const trail = this.selectedTrail;

        this.showPopup = true;
        this.name = trail.TrailName;
        this.displayDistance = trail.Distance + ' km';

        const lLatLng = L.latLng(trail.Lat, trail.Lng);

        this.popup = L.popup({
            className: 'trailPopup',
            minWidth: 250,
            maxWidth: 250,
            offset: new L.Point(0, 5),
        })
        .setLatLng(lLatLng)
        .setContent(this.$el as HTMLElement)
        .openOn(this.map);
    }
}
