export interface IFrontCountryCampsite {
    Name: string;
    SectionInfo: string;
    ElectricalService: string;
    AllowedEquipment: number;
    SiteLength: number;
    SiteWidth: number;
    SiteDrivewayWidth: number;
    CampsiteSlope: string;
    SiteShade: string;
    Privacy: string;
    Accessible: string;
    Lat: number;
    Long: number;
}

export class FrontCountryCampsite implements IFrontCountryCampsite {
    public Name: string;
    public SectionInfo: string;
    public ElectricalService: string;
    public AllowedEquipment: number;
    public SiteLength: number;
    public SiteWidth: number;
    public SiteDrivewayWidth: number;
    public CampsiteSlope: string;
    public SiteShade: string;
    public Privacy: string;
    public Accessible: string;
    public Lat: number;
    public Long: number;

    constructor(frontCountryObject: any) {
        this.Name = frontCountryObject.properties.SiteName;
        this.SectionInfo = frontCountryObject.properties.SectionInfo;

        if (frontCountryObject.properties.Electrical_Service === 0) {
            this.ElectricalService = 'No';
        } else {
            this.ElectricalService = 'Yes';
        }

        this.AllowedEquipment = frontCountryObject.properties.Allowed_Equipment;
        this.SiteLength = frontCountryObject.properties.Site_Length;
        this.SiteWidth = frontCountryObject.properties.Site_Width;
        this.SiteDrivewayWidth = frontCountryObject.properties.Site_Driveway_Width;

        if (frontCountryObject.properties.Campsite_Slope === 0) {
            this.CampsiteSlope = 'Flat';
        } else if (frontCountryObject.properties.Campsite_Slope === 1) {
            this.CampsiteSlope = 'Moderate';
        } else {
            this.CampsiteSlope = 'High';
        }

        if (frontCountryObject.properties.Site_Shade === 0) {
            this.SiteShade = 'None';
        } else if (frontCountryObject.properties.Site_Shade === 1) {
            this.SiteShade = 'Partly';
        } else {
            this.SiteShade = 'Fully';
        }

        if (frontCountryObject.properties.Privacy === 0) {
            this.Privacy = 'Poor';
        } else if (frontCountryObject.properties.Privacy === 1) {
            this.Privacy = 'Hair';
        } else if (frontCountryObject.properties.Privacy === 2) {
            this.Privacy = 'Moderate';
        } else {
            this.Privacy = 'Good';
        }

        if (frontCountryObject.properties.Accessible === 0) {
            this.Accessible = 'No';
        } else {
            this.Accessible = 'Yes';
        }

        this.Lat = frontCountryObject.geometry.coordinates[1];
        this.Long = frontCountryObject.geometry.coordinates[0];
    }
}
