export interface IYurt {
    Name: string;
    Lat: number;
    Long: number;
}

export class Yurt implements IYurt {
    public Name: string;
    public Lat: number;
    public Long: number;

    constructor(yurtObject: any) {
        this.Name = yurtObject.properties.SiteName;
        this.Lat = yurtObject.geometry.coordinates[1];
        this.Long = yurtObject.geometry.coordinates[0];
    }
}
