

































import { Component, Prop, Vue } from 'vue-property-decorator';
import L from 'leaflet';
import * as esri from 'esri-leaflet';
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.js';
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css';

@Component
export default class SearchManager extends Vue {

    public enteredValue: string = '';
    public selectedValue: string = '';
    public layerName = 'BackcountrySites';
    public layerSearchField = 'Name';
    public queryParamSearchField = 'Name';

    public isEnterActive: boolean = false;
    public backcountryCampsiteData: any = [];
    public cabinCampsiteData: any = [];
    public frontcountryCampsiteData: any = [];
    public oasisCampsiteData: any = [];
    public oTentikCampsiteData: any = [];
    public yurtCampsiteData: any = [];

    public campsiteResults: any = [];
    public pulseIntervalInMillis = 3000;
    public options: any;

    get map(): L.Map {
        return this.$store.state.map;
    }

    get config() {
        return this.$store.state.config;
    }

    get backcountryGeoJSONURL() {
        return this.config.BackcountryGeoJSONURL;
    }

    get cabinGeoJSONURL() {
        return this.config.CabinGeoJSONURL;
    }

    get frontcountryGeoJSONURL() {
        return this.config.FrontcountryGeoJSONURL;
    }

    get oasisGeoJSONURL() {
        return this.config.OasisGeoJSONURL;
    }

    get oTentikGeoJSONURL() {
        return this.config.oTentikGeoJSONURL;
    }

    get yurtGeoJSONURL() {
        return this.config.YurtGeoJSONURL;
    }

    get resultOptions() {
        return this.campsiteResults;
    }

    public clearResult() {
        this.selectedValue = '';
    }

    public searchInput(inputValue: any, update: any, abort: any) {
        if (inputValue && inputValue.length > 0) {
            this.campsiteResults = [];

            this.backcountryCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'Backcountry: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Backcountry',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });

            this.cabinCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'Cabin: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Cabin',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });

            this.frontcountryCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'Frontcountry: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Frontcountry',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });

            this.oasisCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'Oasis: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Oasis',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });

            this.oTentikCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'oTENTik: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'oTENTik',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });

            this.yurtCampsiteData.forEach((campsite: any) => {
                inputValue = inputValue.toUpperCase();
                if (campsite.properties.Name.startsWith(inputValue)) {
                    update(() => {
                        this.campsiteResults.push({
                            label: 'Yurt: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Yurt',
                            geometry: campsite.geometry,
                        });
                    });
                }
            });
        } else {
            abort();
        }

        this.isEnterActive = false;
    }

    public selectedResult(inputValue: any) {
        if (inputValue !== undefined && inputValue !== null) {
            if (inputValue.geometry !== undefined) {
                const latitude = inputValue.geometry.coordinates[1];
                const longitude = inputValue.geometry.coordinates[0];

                const latLon = new L.LatLng(latitude, longitude);
                const type = inputValue.type;
                let zoomLevel = 18;

                if (type === 'Backcountry') {
                    zoomLevel = 15;
                }

                this.map.setView(latLon, zoomLevel);

                // @ts-ignore
                const pulsingIcon = L.icon.pulse(
                    {
                        iconSize: [15, 15],
                        color: 'red',
                        fillColor: 'transparent',
                        heartbeat: 1,
                    },
                );
                const markerGroup = L.layerGroup().addTo(this.map);
                L.marker([latitude, longitude], {icon: pulsingIcon}).addTo(markerGroup);

                const removeMarkerGroupFunction = () => { this.map.removeLayer(markerGroup); };
                setInterval(removeMarkerGroupFunction, this.pulseIntervalInMillis);
            }
        }
    }

    public setEnteredValue(enteredValue: string) {
        this.enteredValue = enteredValue;
    }

    public onPopUp() {
        if (this.isEnterActive) {
            // @ts-ignore
            this.$refs.qSelRef.hidePopup();
        }
    }

    public enterClicked() {
        this.isEnterActive = true;

        if (this.enteredValue && this.enteredValue.length > 0) {
            this.campsiteResults = [];
            for (const campsite of this.backcountryCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'Backcountry: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Backcountry',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }

            for (const campsite of this.cabinCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'Cabin: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Cabin',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }

            for (const campsite of this.frontcountryCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'Frontcountry: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Frontcountry',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }

            for (const campsite of this.oasisCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'Oasis: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Oasis',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }

            for (const campsite of this.oTentikCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'oTENTik: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'oTENTik',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }

            for (const campsite of this.yurtCampsiteData) {
                this.enteredValue = this.enteredValue.toUpperCase();
                if (campsite.properties.Name.startsWith(this.enteredValue)) {
                    const searchResult = {
                            label: 'Yurt: ' + campsite.properties.Name,
                            value: campsite.properties.OBJECTID,
                            type: 'Yurt',
                            geometry: campsite.geometry,
                        };
                    this.selectedResult(searchResult);
                    break;
                }
            }
        }

        this.selectedResult(this.enteredValue);
    }

    public mounted() {
        fetch(this.backcountryGeoJSONURL).then((res) => res.json()).then((data) => {
            this.backcountryCampsiteData = data.features;
        });
        fetch(this.cabinGeoJSONURL).then((res) => res.json()).then((data) => {
            this.cabinCampsiteData = data.features;
        });
        fetch(this.frontcountryGeoJSONURL).then((res) => res.json()).then((data) => {
            this.frontcountryCampsiteData = data.features;
        });
        fetch(this.oasisGeoJSONURL).then((res) => res.json()).then((data) => {
            this.oasisCampsiteData = data.features;
        });
        fetch(this.oTentikGeoJSONURL).then((res) => res.json()).then((data) => {
            this.oTentikCampsiteData = data.features;
        });
        fetch(this.yurtGeoJSONURL).then((res) => res.json()).then((data) => {
            this.yurtCampsiteData = data.features;
        });
    }
}

