




















































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Cabin } from '../models/Cabin';

@Component
export default class PopupCabin extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public showPopup = false;
  public popup: L.Popup | null = null;
  public nameDisplay = '';
  public sectionInfo = '';
  public petFriendly = '';
  public lat = 0;
  public long = 0;

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showCabinPopup') {
      this.onShowCabinPopup();
    }
  });

  get config() {
    return this.$store.state.config;
  }

  get cabinPermDetails() {
    return this.config.CabinsLayer.popupPermDetails;
  }

  get maximumAdultOccupants() {
    return this.cabinPermDetails.maximumAdultOccupants;
  }

  get internalHeatSource() {
    return this.cabinPermDetails.internalHeatSource;
  }

  get electricityAvailable() {
    return this.cabinPermDetails.electricityAvailable;
  }

  get lightSourceProvided() {
    return this.cabinPermDetails.lightSourceProvided;
  }

  get privacy() {
    return this.cabinPermDetails.privacy;
  }

  get generatorUsage() {
    return this.cabinPermDetails.generatorUsage;
  }

  get selectedCabin(): Cabin {
    return this.$store.state.layers.selectedCabin;
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get map(): L.Map {
    return this.$store.state.map;
  }

  public onShowCabinPopup() {
    const cabin = this.selectedCabin;

    this.showPopup = true;
    this.nameDisplay = cabin.Name;
    this.sectionInfo = cabin.SectionInfo;

    if (cabin.PetFriendly) {
      this.petFriendly = 'Yes';
    } else {
      this.petFriendly = 'No';
    }

    this.lat = cabin.Lat;
    this.long = cabin.Long;

    let lLatlng = null;
    lLatlng = L.latLng(cabin.Lat, cabin.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'cabinPopup',
      minWidth: 280,
      maxWidth: 280,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }
}

