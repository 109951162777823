
















































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Otentik } from '../models/Otentik';

@Component
export default class PopupOtentik extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public showPopup = false;
  public popup: L.Popup | null = null;
  public nameDisplay = '';
  public sectionInfo = '';
  public internalHeatSourceAvailable = '';
  public accessible = '';
  public maximumVehicles = 0;
  public petFriendly = '';
  public lat = 0;
  public long = 0;

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showOtentikPopup') {
      this.onShowOtentikPopup();
    }
  });

  get config() {
    return this.$store.state.config;
  }

  get otentikPermDetails() {
    return this.config.oTENTikLayer.popupPermDetails;
  }

  get maximumAdultOccupants() {
    return this.otentikPermDetails.maximumAdultOccupants;
  }

  get lightSourceProvided() {
    return this.otentikPermDetails.lightSourceProvided;
  }

  get selectedOtentik(): Otentik {
    return this.$store.state.layers.selectedOtentik;
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get map(): L.Map {
    return this.$store.state.map;
  }

  public onShowOtentikPopup() {
    const otentik = this.selectedOtentik;

    this.showPopup = true;
    this.nameDisplay = otentik.Name;
    this.sectionInfo = otentik.SectionInfo;

    if (otentik.InternalHeatSourceAvailable) {
      this.internalHeatSourceAvailable = 'Yes';
    } else {
      this.internalHeatSourceAvailable = 'No';
    }

    if (otentik.Accessible) {
      this.accessible = 'Yes';
    } else {
      this.accessible = 'No';
    }

    this.maximumVehicles = otentik.MaximumVehicles;

    if (otentik.PetFriendly) {
      this.petFriendly = 'Yes';
    } else {
      this.petFriendly = 'No';
    }

    this.lat = otentik.Lat;
    this.long = otentik.Long;

    let lLatlng = null;
    lLatlng = L.latLng(otentik.Lat, otentik.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'otentikPopup',
      minWidth: 280,
      maxWidth: 280,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }
}

