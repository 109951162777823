import Vue from 'vue';
import Vuex from 'vuex';
import { Campsite } from '@/models/Campsite';
import { Cabin } from '@/models/Cabin';
import { Oasis } from '@/models/Oasis';
import { Yurt } from '@/models/Yurt';
import { Otentik } from '@/models/Otentik';
import { FrontCountryCampsite } from '@/models/FrontCountryCampsite';
import { Trail } from '@/models/Trail';
import { Portage } from '@/models/Portage';

Vue.use(Vuex);

export interface LayerState {
    selectedCampsite: Campsite | null;
    selectedCabin: Cabin | null;
    selectedOasis: Oasis | null;
    selectedYurt: Yurt | null;
    selectedOtentik: Otentik | null;
    selectedFrontCountryCampsite: FrontCountryCampsite | null;
    selectedTrail: Trail | null;
    selectedPortage: Portage | null;
}

export default{
    state: {
        selectedCampsite: null,
        selectedCabin: null,
        selectedOasis: null,
        selectedYurt: null,
        selectedOtentik: null,
        selectedFrontCountryCampsite: null,
        selectedTrail: null,
        selectedPortage: null,
    } as LayerState,
    mutations: {
        SET_SELECTED_CAMPSITE(state: LayerState, campsite: Campsite | null) {
            state.selectedCampsite = campsite;
        },
        SET_SELECTED_CABIN(state: LayerState, cabin: Cabin | null) {
            state.selectedCabin = cabin;
        },
        SET_SELECTED_OASIS(state: LayerState, oasis: Oasis | null) {
            state.selectedOasis = oasis;
        },
        SET_SELECTED_YURT(state: LayerState, yurt: Yurt | null) {
            state.selectedYurt = yurt;
        },
        SET_SELECTED_OTENTIK(state: LayerState, otentik: Otentik | null) {
            state.selectedOtentik = otentik;
        },
        SET_SELECTED_FRONTCOUNTRYCAMPSITE(state: LayerState, frontCountryCampsite: FrontCountryCampsite | null) {
            state.selectedFrontCountryCampsite = frontCountryCampsite;
        },
        SET_SELECTED_TRAIL(state: LayerState, trail: Trail | null) {
            state.selectedTrail = trail;
        },
        SET_SELECTED_PORTAGE(state: LayerState, portage: Portage | null) {
            state.selectedPortage = portage;
        },
    },
    actions: {
        changeSelectedCampsite(context: any, campsite: Campsite) {
            context.commit('SET_SELECTED_CAMPSITE', campsite);
        },
        changeSelectedCabin(context: any, cabin: Cabin) {
            context.commit('SET_SELECTED_CABIN', cabin);
        },
        changeSelectedOasis(context: any, oasis: Oasis) {
            context.commit('SET_SELECTED_OASIS', oasis);
        },
        changeSelectedYurt(context: any, yurt: Yurt) {
            context.commit('SET_SELECTED_YURT', yurt);
        },
        changeSelectedOtentik(context: any, otentik: Otentik) {
            context.commit('SET_SELECTED_OTENTIK', otentik);
        },
        changeSelectedFrontCountryCampsite(context: any, frontCountryCampsite: FrontCountryCampsite) {
            context.commit('SET_SELECTED_FRONTCOUNTRYCAMPSITE', frontCountryCampsite);
        },
        changeSelectedTrail(context: any, trail: Trail) {
            context.commit('SET_SELECTED_TRAIL', trail);
        },
        changeSelectedPortage(context: any, portage: Portage) {
            context.commit('SET_SELECTED_PORTAGE', portage);
        },
        showCampsitePopup(context: any) {
            //
        },
        showCabinPopup(context: any) {
            //
        },
        showOasisPopup(context: any) {
            //
        },
        showYurtPopup(context: any) {
            //
        },
        showOtentikPopup(context: any) {
            //
        },
        showFrontCountryCampsitePopup(context: any) {
            //
        },
        showTrailPopup(context: any) {
            //
        },
        showPortagePopup(context: any) {
            //
        },
        toggleBasemap(content: any) {
            //
        },
    },
};
