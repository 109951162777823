

























































































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Campsite } from '../models/Campsite';

@Component
export default class PopupCampsite extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public maxNoteCharacters = 60;
  public isPanoButtonVisible = false;
  public showPopup = false;
  public showMoreDialog = false;
  public show360Dialog = false;
  public popup: L.Popup | null = null;

  public nameDisplay = '';
  public treeCoverAmount = 0;
  public treeCoverType = -1;
  public underbrushAmount = -1;
  public windExposureFrom = '';
  public hikingAccess = -1;
  public canoeAccess = -1;
  public drinkingWater = -1;
  public tentPads = -1;
  public picnicTables = -1;
  public layoutImage = '';
  public notes = '';
  public fireGrates = false;
  public panoImageFileName = '';
  public panoImageAuthor = '';
  public panoImagePitch = 0;
  public panoImageYaw = 0;

  public lat = 0;
  public long = 0;
  public northing = 0;
  public easting = 0;

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showCampsitePopup') {
      this.onShowCampsitePopup();
    }
  });

get isMobile(): boolean {
    return (this.$q.screen.width <= this.widthThresholdValue) || (this.$q.screen.height <= this.heightThresholdValue);
}

  get map(): L.Map {
    return this.$store.state.map;
  }

  get config() {
    return this.$store.state.config;
  }

  get panoImagePrefix() {
    if (this.isMobile) {
      return this.config.PanoMobileImagePrefix;
    } else {
      return this.config.PanoImagePrefix;
    }
  }

  get selectedCampsite(): Campsite {
    return this.$store.state.layers.selectedCampsite;
  }

  get treeCoverAmountDisplay() {
    const value = this.treeCoverAmount;

    if (value === 1) {
      return 'None';
    } else if (value === 2) {
      return 'Light';
    } else if (value === 3) {
      return 'Medium';
    } else if (value === 4) {
      return 'Heavy';
    } else {
      return 'Unknown';
    }
  }

  get treeCoverTypeDisplay() {
    const value = this.treeCoverType;

    if (value === 1) {
      return 'None';
    } else if (value === 2) {
      return 'Mixed';
    } else if (value === 3) {
      return 'Softwood';
    } else if (value === 4) {
      return 'Hemlock';
    } else if (value === 5) {
      return 'Hemlock & Pine';
    } else if (value === 6) {
      return 'Pine';
    } else {
      return 'Unknown';
    }
  }

  get underbrushAmountDisplay() {
    const value = this.underbrushAmount;

    if (value === 1) {
      return 'None';
    } else if (value === 2) {
      return 'Light';
    } else if (value === 3) {
      return 'Medium';
    } else if (value === 4) {
      return 'Heavy';
    } else {
      return 'Unknown';
    }
  }

  get hikingAccessDisplay() {
    const value = this.hikingAccess;

    if (value === 0) {
      return 'No';
    } else {
      return 'Yes';
    }
  }

  get canoeAccessDisplay() {
    const value = this.canoeAccess;

    if (value === 0) {
      return 'No';
    } else {
      return 'Yes';
    }
  }

  get drinkingWaterDisplay() {
    const value = this.drinkingWater;

    if (value === 0) {
      return 'Lake';
    } else if (value === 1) {
      return 'River';
    } else if (value === 2) {
      return 'Brook';
    } else if (value === 3) {
      return 'Lake and Brook';
    } else {
      return 'Unknown';
    }
  }

  get hasFireGrate() {
    const value = this.fireGrates;

    if (value === false) {
      return 'No';
    } else {
      return 'Yes';
    }
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get notesDisplay() {
    const value = this.notes;
    if (value.length > this.maxNoteCharacters) {
      return value.substring(0, this.maxNoteCharacters - 3) + '...';
    }
    return value;
  }

  get isNotesMoreVisible() {
    return this.notes.length > this.maxNoteCharacters;
  }

  public onShowCampsitePopup() {
    const campsite = this.selectedCampsite;

    this.panoImageFileName = campsite.PanoImageFileName;
    this.isPanoButtonVisible = !this.isEmptyOrSpaces(this.panoImageFileName);
    this.panoImageAuthor = campsite.PanoImageAuthor;
    this.panoImagePitch = campsite.PanoImagePitch;
    this.panoImageYaw = campsite.PanoImageYaw;

    this.showPopup = true;
    this.nameDisplay = campsite.Name;
    this.treeCoverAmount = campsite.TreeCoverAmount;
    this.treeCoverType = campsite.TreeCoverType;
    this.underbrushAmount = campsite.UnderbrushAmount;
    this.windExposureFrom = campsite.WindExposureFrom;
    this.hikingAccess = campsite.HikingAccess;
    this.canoeAccess = campsite.CanoeAccess;
    this.drinkingWater = campsite.DrinkingWater;
    this.tentPads = campsite.TentPads;
    this.layoutImage = campsite.LayoutImage;
    this.notes = campsite.Notes;
    this.fireGrates = campsite.HasFireGrates;


    this.lat = campsite.Lat;
    this.long = campsite.Long;
    this.northing = campsite.Northing;
    this.easting = campsite.Easting;

    let lLatlng = null;
    lLatlng = L.latLng(campsite.Lat, campsite.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'campsitePopup',
      minWidth: 250,
      maxWidth: 250,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }

  public openMoreDialog() {
    this.showMoreDialog = true;
  }

  public openLayoutImage() {
    this.$store.dispatch('showLightBox');
  }

  public async open360Dialog() {
    this.show360Dialog = true;
    const panoramaDiv = await this.waitForElm('#panorama');
    // @ts-ignore
    pannellum.viewer(panoramaDiv, {
      type: 'equirectangular',
      panorama:  this.panoImagePrefix + this.panoImageFileName,
      autoLoad: true,
      title: 'Site: ' + this.nameDisplay,
      author: this.panoImageAuthor,
      pitch: this.panoImagePitch,
      yaw: this.panoImageYaw,
    });
  }

  public waitForElm(selector: string) {
    return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
  }

  public isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
  }
}
