














import { Component, Vue } from 'vue-property-decorator';
import LeafletMap from '@/components/LeafletMap.vue'; // @ is an alias to /src
import PopupCampsite from '@/components/PopupCampsite.vue';
import PopupCabin from '@/components/PopupCabin.vue';
import PopupOasis from '@/components/PopupOasis.vue';
import PopupYurt from '@/components/PopupYurt.vue';
import PopupOtentik from '@/components/PopupOtentik.vue';
import PopupFrontCountryCampsite from '@/components/PopupFrontCountryCampsite.vue';
import PopupPortage from '@/components/PopupPortage.vue';
import PopupTrail from '@/components/PopupTrail.vue';

@Component({
  components: {
    LeafletMap,
    PopupCampsite,
    PopupCabin,
    PopupOasis,
    PopupYurt,
    PopupOtentik,
    PopupFrontCountryCampsite,
    PopupPortage,
    PopupTrail,
  },
})
export default class Home extends Vue {}
