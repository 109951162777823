
















































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Oasis } from '../models/Oasis';

@Component
export default class PopupOasis extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public showPopup = false;
  public popup: L.Popup | null = null;
  public nameDisplay = '';
  public sectionInfo = '';
  public lat = 0;
  public long = 0;

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showOasisPopup') {
      this.onShowOasisPopup();
    }
  });

  get config() {
    return this.$store.state.config;
  }

  get oasisPermDetails() {
    return this.config.OasisLayer.popupPermDetails;
  }

  get maximumAdultOccupants() {
    return this.oasisPermDetails.maximumAdultOccupants;
  }

  get lightSourceProvided() {
    return this.oasisPermDetails.lightSourceProvided;
  }

  get maximumParking() {
    return this.oasisPermDetails.maximumParking;
  }

  get electricityAvailable() {
    return this.oasisPermDetails.electricityAvailable;
  }

  get bbqProvided() {
    return this.oasisPermDetails.bbqProvided;
  }

  get foodLockersProvided() {
    return this.oasisPermDetails.foodLockersProvided;
  }

  get selectedOasis(): Oasis {
    return this.$store.state.layers.selectedOasis;
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get map(): L.Map {
    return this.$store.state.map;
  }

  public onShowOasisPopup() {
    const oasis = this.selectedOasis;

    this.showPopup = true;
    this.nameDisplay = oasis.Name;
    this.sectionInfo = oasis.SectionInfo;

    this.lat = oasis.Lat;
    this.long = oasis.Long;

    let lLatlng = null;
    lLatlng = L.latLng(oasis.Lat, oasis.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'oasisPopup',
      minWidth: 280,
      maxWidth: 280,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }
}

