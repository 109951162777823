export interface IOtentik {
    Name: string;
    SectionInfo: string;
    InternalHeatSourceAvailable: boolean;
    Accessible: boolean;
    MaximumVehicles: number;
    PetFriendly: boolean;
    Lat: number;
    Long: number;
}

export class Otentik implements IOtentik {
    public Name: string;
    public SectionInfo: string;
    public InternalHeatSourceAvailable: boolean;
    public Accessible: boolean;
    public MaximumVehicles: number;
    public PetFriendly: boolean;
    public Lat: number;
    public Long: number;

    constructor(otentikObject: any) {
        this.Name = otentikObject.properties.CampsiteName;
        this.SectionInfo = otentikObject.properties.SectionInfo;

        if (otentikObject.properties.InternalHeatSourceAvailable === 0) {
            this.InternalHeatSourceAvailable = false;
        } else {
            this.InternalHeatSourceAvailable = true;
        }

        if (otentikObject.properties.Accessible === 0) {
            this.Accessible = false;
        } else {
            this.Accessible = true;
        }

        this.MaximumVehicles = otentikObject.properties.Maximum_Vehicles;

        if (otentikObject.properties.Pet_Friendly === 0) {
            this.PetFriendly = false;
        } else {
            this.PetFriendly = true;
        }

        this.Lat = otentikObject.geometry.coordinates[1];
        this.Long = otentikObject.geometry.coordinates[0];
    }
}
