export interface IOasis {
    Name: string;
    SectionInfo: string;
    Lat: number;
    Long: number;
}

export class Oasis implements IOasis {
    public Name: string;
    public SectionInfo: string;
    public Lat: number;
    public Long: number;

    constructor(oasisObject: any) {
        this.Name = oasisObject.properties.SiteName;
        this.SectionInfo = oasisObject.properties.SectionInfo;
        this.Lat = oasisObject.geometry.coordinates[1];
        this.Long = oasisObject.geometry.coordinates[0];
    }
}
