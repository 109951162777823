

































import { Component, Vue } from 'vue-property-decorator';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import proj4 from 'proj4';

@Component
export default class MeasureTool extends Vue {
    public showMeasureToolbar: boolean = false;
    public currentMeasurement: number = 0;
    public isUndoDisabled: boolean = true;
    public drawLine: any = null;
    public coords: any = [];
    public projectedCoords: any = [];
    public drawLayer: L.GeoJSON | null = null;
    public areaOverlayLayer: L.GeoJSON | null = null;
    public drawLineStyle = {
        color: '#DC1C2E',
        weight: 4,
        opacity: 1,
    };
    public drawPolygonStyle = {
        color: '#000000',
        fillColor: '#000000',
        weight: 0,
        opacity: 1,
        fillOpacity: 0.3,
    };

    get formattedMeasurement() {
        if (this.currentMeasurement >= 1000) {
            return `${Math.round((this.currentMeasurement / 1000) * 100) / 100} km`;
        }
        return `${Math.round(this.currentMeasurement * 100) / 100} m`;
    }

    get map() {
        return this.$store.state.map;
    }

    public onToggleMeasure() {
        this.showMeasureToolbar = !this.showMeasureToolbar;
        this.showMeasureToolbar ? this.onInitMeasure() : this.onCancelMeasure();
    }

    public onInitMeasure() {
        // @ts-ignore - no typedefs
        L.drawLocal.draw.handlers.polyline.tooltip.start = 'Click to start drawing a line';
        // @ts-ignore - no typedefs
        L.drawLocal.draw.handlers.polyline.tooltip.cont = '';
        // @ts-ignore - no typedefs
        L.drawLocal.draw.handlers.polyline.tooltip.end = '';
        // @ts-ignore - no typedefs
        this.drawLine = new L.Draw.Polyline(this.map, {
            showLength: false,
            allowIntersection: true,
            drawError: {
                color: '#ffa500',
                message: '<strong>Opps...sorry!<strong> you can\'t draw that!',
            },
            shapeOptions: {
                color: '#003DA5',
                opacity: 1.0,
                weight: 6,
            },
        });
        this.drawLine.enable();

        // @ts-ignore
        proj4.defs('EPSG:2961', '+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs');

        let firstMarker = null;
        // @ts-ignore - no typedefs
        this.map.on(L.Draw.Event.DRAWVERTEX, (e) => {
            if (e.layers.getLayers().length === 1) {
                firstMarker = e.layers.getLayers()[0];
                firstMarker.on('click', (v: any) => {
                    this.drawLine.addVertex(v.latlng);
                    this.drawLine.completeShape();
                });
            }
            this.coords = [];
            this.projectedCoords = [];
            for (const feature of e.layers.toGeoJSON().features) {
                this.coords.push([feature.geometry.coordinates[1], feature.geometry.coordinates[0]]);
                const projected = proj4('EPSG:2961', feature.geometry.coordinates);
                this.projectedCoords.push(projected);
            }
            this.recalcDistance();
            if (this.projectedCoords.length > 1) {
                this.isUndoDisabled = false;
            }
        });

        // @ts-ignore - no typedefs
        this.map.on(L.Draw.Event.CREATED, (e) => {
            // drawstop gets fired when created so disable it!
            this.map.off('draw:drawstop');
            this.drawLayer = L.geoJSON(e.layer.toGeoJSON(),
                {
                    style: this.drawLineStyle,
                });

            this.map.removeLayer(e.layer);
            this.drawLayer.addTo(this.map);
        });

        // @ts-ignore - no typedefs
        this.map.on('draw:drawstop', (e) => {
            this.onCancelMeasure();
        });
    }

    public recalcDistance() {
        if (this.projectedCoords.length > 1) {
            let sum = 0;
            for (let i = 0; i < this.projectedCoords.length - 1; i++) {
                sum += this.utmDistance(this.projectedCoords[i][0], this.projectedCoords[i][1],
                                        this.projectedCoords[i + 1][0], this.projectedCoords[i + 1][1]);
            }
            this.currentMeasurement = sum;
        } else {
            this.currentMeasurement = 0;
        }
    }

    public utmDistance(x1: number, y1: number, x2: number, y2: number) {
        const xDiff = Math.abs(x1 - x2);
        const yDiff = Math.abs(y1 - y2);
        return Math.sqrt((xDiff * xDiff) + (yDiff * yDiff));
    }

    public onUndo() {
        if (this.drawLayer) {
            this.map.removeLayer(this.drawLayer);
            this.drawLayer = null;
            this.areaOverlayLayer?.removeFrom(this.map);
            this.areaOverlayLayer = null;
            this.currentMeasurement = 0;
            // @ts-ignore
            this.map.off(L.Draw.Event.CREATED);
            // @ts-ignore
            this.map.off(L.Draw.Event.DRAWVERTEX);
            this.map.off('draw:drawstop');
            this.onInitMeasure();
        } else {
            if (this.drawLine) {
                this.drawLine.deleteLastVertex();
            }
        }
    }

    public onCancelMeasure() {
        // this.$store.dispatch('enableMapClick');
        if (this.drawLine) {
            // @ts-ignore
            this.map.off(L.Draw.Event.CREATED);
            // @ts-ignore
            this.map.off(L.Draw.Event.DRAWVERTEX);
            this.map.off('draw:drawstop');
            this.drawLine.disable();
            this.drawLine = null;
        }
        if (this.drawLayer) {
            this.map.removeLayer(this.drawLayer);
            this.drawLayer = null;
        }
        if (this.areaOverlayLayer) {
            this.map.removeLayer(this.areaOverlayLayer);
            this.areaOverlayLayer = null;
        }
        this.showMeasureToolbar = false;
        this.currentMeasurement = 0;
    }
}

