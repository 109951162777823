



























































































import QVueGlobals from 'quasar';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { FrontCountryCampsite } from '../models/FrontCountryCampsite';

@Component
export default class PopupFrontCountryCampsite extends Vue {
  public widthThresholdValue: number = 768;
  public heightThresholdValue: number = 415;
  public showPopup = false;
  public showAllowedEquipmentDialog = false;
  public isFeetViewModel: boolean = false;
  public popup: L.Popup | null = null;
  public nameDisplay = '';
  public sectionInfo = '';
  public allowedEquipment = -1;
  public allowedEquipmentList: string[] = [];
  public electricalService = '';
  public siteLengthInFt = 0;
  public siteWidthInFt = 0;
  public siteDrivewayWidthInFt = 0;
  public campsiteSlope = '';
  public siteShade = '';
  public privacy = '';
  public accessible = '';
  public lat = 0;
  public long = 0;
  public unit = 'ft';

  public lastLatLon: L.LatLng = new LatLng(0, 0);

  public subscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showFrontCountryCampsitePopup') {
      this.onShowFrontCountryCampsitePopup();
    }
  });

  get config() {
    return this.$store.state.config;
  }

  get measurementSystem() {
    return this.$store.state.measurementSystem;
  }

  get frontCountryPermDetails() {
    return this.config.FrontcountrySitesLayer.popupPermDetails;
  }

  get selectedFrontCountryCampsite(): FrontCountryCampsite {
    return this.$store.state.layers.selectedFrontCountryCampsite;
  }

  get displaySiteLength() {
    if (this.measurementSystem === 'imperial') {
      return this.siteLengthInFt + 'ft';
    } else {
      return this.convertFtToM(this.siteLengthInFt) + 'm';
    }
  }

  get displaySiteWidth() {
    if (this.measurementSystem === 'imperial') {
      return this.siteWidthInFt + 'ft';
    } else {
      return this.convertFtToM(this.siteWidthInFt) + 'm';
    }
  }

  get displaySiteDrivewayWidth() {
    if (this.measurementSystem === 'imperial') {
      return this.siteDrivewayWidthInFt + 'ft';
    } else {
      return this.convertFtToM(this.siteDrivewayWidthInFt) + 'm';
    }
  }

  get displayLat() {
    return this.lat.toFixed(4);
  }

  get displayLong() {
    return this.long.toFixed(4);
  }

  get map(): L.Map {
    return this.$store.state.map;
  }

  public onUpdateMeasurementToggle() {
    let value = 'imperial';

    if (!this.isFeetViewModel) {
      value = 'metric';
    }

    this.$store.dispatch('setMeasurementSystem', value);
  }

  public onShowFrontCountryCampsitePopup() {
    if (this.measurementSystem === 'imperial') {
      this.isFeetViewModel = true;
    } else {
      this.isFeetViewModel = false;
    }

    const frontCountry = this.selectedFrontCountryCampsite;

    this.showPopup = true;
    this.nameDisplay = frontCountry.Name;
    this.sectionInfo = frontCountry.SectionInfo;
    this.allowedEquipment = frontCountry.AllowedEquipment;

    switch (this.allowedEquipment) {
      case 2:
        this.allowedEquipmentList = ['Tents', 'Van/Pick-up', 'Tent Trailer', 'Trailer or Motorhome up to 21\''];
        break;
      case 3:
        this.allowedEquipmentList = ['Tents', 'Van/Pick-up', 'Tent Trailer', 'Trailer or Motorhome up to 21\'', 'Trailer or Motorhome up to 24\''];
        break;
      case 4:
        this.allowedEquipmentList = ['Tents', 'Van/Pick-up', 'Tent Trailer', 'Trailer or Motorhome up to 21\'', 'Trailer or Motorhome up to 24\'', 'Trailer or Motorhome up to 27\''];
        break;
      case 5:
        this.allowedEquipmentList = ['Tents', 'Van/Pick-up', 'Tent Trailer', 'Trailer or Motorhome up to 21\'', 'Trailer or Motorhome up to 24\'', 'Trailer or Motorhome up to 27\'', 'Trailer or Motorhome up to 35\''];
        break;
      case 6:
        this.allowedEquipmentList = ['Tents', 'Van/Pick-up', 'Tent Trailer', 'Trailer or Motorhome up to 21\'', 'Trailer or Motorhome up to 24\'', 'Trailer or Motorhome up to 27\'', 'Trailer or Motorhome up to 35\'', 'Trailer or Motorhome over 35\''];
        break;
      case 7:
        this.allowedEquipmentList = ['Tent Trailer', 'Trailer or Motorhome up to 21\'', 'Trailer or Motorhome up to 24\'', 'Trailer or Motorhome up to 27\' only'];
        break;
      case 8:
        this.allowedEquipmentList = ['Trailer or Motorhome up to 24\'', 'Trailer or Motorhome up to 27\'', 'Trailer or Motorhome up to 35\' only'];
        break;
      case 9:
        this.allowedEquipmentList = ['Trailer or Motorhome up to 27\'', 'Trailer or Motorhome up to 35\''];
        break;
      case 10:
        this.allowedEquipmentList = ['Trailer or Motorhome up to 27\'', 'Trailer or Motorhome up to 35\'', 'Trailer or Motorhome over 35\' only'];
        break;
      default:
        this.allowedEquipmentList = [];
    }

    this.electricalService = frontCountry.ElectricalService;
    this.siteLengthInFt = frontCountry.SiteLength;
    this.siteWidthInFt = frontCountry.SiteWidth;
    this.siteDrivewayWidthInFt = frontCountry.SiteDrivewayWidth;
    this.campsiteSlope = frontCountry.CampsiteSlope;
    this.siteShade = frontCountry.SiteShade;
    this.privacy = frontCountry.Privacy;
    this.accessible = frontCountry.Accessible;

    this.lat = frontCountry.Lat;
    this.long = frontCountry.Long;

    let lLatlng = null;
    lLatlng = L.latLng(frontCountry.Lat, frontCountry.Long);
    this.lastLatLon = lLatlng;

    this.popup = L.popup({
      className: 'frontCountryPopup',
      minWidth: 280,
      maxWidth: 280,
      offset: new L.Point(0, -5),
    })
      .setLatLng(lLatlng)
      .setContent(this.$el as HTMLElement)
      .openOn(this.map);
  }

  public openAllowedEquipmentDialog() {
    this.showAllowedEquipmentDialog = true;
  }

  private convertFtToM(value: number) {
    return (value / 3.281).toFixed(0);
  }
}

