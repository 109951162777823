export interface ICampsite {
    Name: string;
    TreeCoverAmount: number;
    TreeCoverType: number;
    UnderbrushAmount: number;
    WindExposureFrom: string;
    HikingAccess: number;
    CanoeAccess: number;
    DrinkingWater: number;
    TentPads: number;
    LayoutImage: string;
    Notes: string;
    HasFireGrates: boolean;
    PanoImageFileName: string;
    PanoImageAuthor: string;
    PanoImagePitch: number;
    PanoImageYaw: number;
    Lat: number;
    Long: number;
  }

export class Campsite implements ICampsite {
    public Name: string;
    public TreeCoverAmount: number;
    public TreeCoverType: number;
    public UnderbrushAmount: number;
    public WindExposureFrom: string;
    public HikingAccess: number;
    public CanoeAccess: number;
    public DrinkingWater: number;
    public TentPads: number;
    public LayoutImage: string;
    public Notes: string;
    public HasFireGrates: boolean;
    public PanoImageFileName: string;
    public PanoImageAuthor: string;
    public PanoImagePitch: number;
    public PanoImageYaw: number;
    public Lat: number;
    public Long: number;
    public Northing: number;
    public Easting: number;

    constructor(campsiteObject: any) {
        this.Name = campsiteObject.properties.Name;
        this.TreeCoverAmount = campsiteObject.properties.TreeCoverAmount;
        this.TreeCoverType = campsiteObject.properties.TreeCoverType;
        this.UnderbrushAmount = campsiteObject.properties.UnderbrushAmount;
        this.WindExposureFrom = campsiteObject.properties.WindExposureFrom;
        this.HikingAccess = campsiteObject.properties.HikingAccess;
        this.CanoeAccess = campsiteObject.properties.CanoeAccess;
        this.DrinkingWater = campsiteObject.properties.DrinkingWater;
        this.TentPads = campsiteObject.properties.TentPads;
        this.LayoutImage = campsiteObject.properties.LayoutImage;
        this.Notes = campsiteObject.properties.Notes;

        if (campsiteObject.properties.FireGrates === 0) {
            this.HasFireGrates = false;
        } else {
            this.HasFireGrates = true;
        }

        this.Northing = campsiteObject.properties.Northing;
        this.Easting = campsiteObject.properties.Easting;
        this.PanoImageFileName = campsiteObject.properties.PanoImageFileName;
        this.PanoImageAuthor = campsiteObject.properties.PanoImageAuthor;
        this.PanoImagePitch = campsiteObject.properties.PanoImagePitch;
        this.PanoImageYaw = campsiteObject.properties.PanoImageYaw;

        this.Lat = campsiteObject.geometry.coordinates[1];
        this.Long = campsiteObject.geometry.coordinates[0];
    }
}

