


















import { Component, Prop, Vue } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as esri from 'esri-leaflet';

@Component
export default class BasemapToggleWidget extends Vue {

  public basemapDetails1: any = null;
  public basemapDetails2: any = null;
  public activeBasemapName: string = '';
  public activeBasemapThumbnail: string = '';

  get map(): L.Map {
    return this.$store.state.map;
  }

  get config() {
    return this.$store.state.config;
  }

  public toggleBasemap() {
    this.$store.dispatch('toggleBasemap');
    let basemapLayer = this.config.basemapLayer;
    this.map.removeLayer(basemapLayer);

    if (this.activeBasemapName === this.basemapDetails1.name) {
      basemapLayer = esri.tiledMapLayer({
          url: this.basemapDetails2.url,
          attribution: this.basemapDetails2.attribution,
          maxZoom: this.basemapDetails2.maxZoom,
      });
      this.config.basemapLayer = basemapLayer;
      this.activeBasemapName = this.basemapDetails2.name;
      basemapLayer.addTo(this.map);

      this.activeBasemapThumbnail = this.basemapDetails1.thumbnail;
    } else if (this.activeBasemapName === this.basemapDetails2.name) {
      basemapLayer = L.tileLayer(
          this.basemapDetails1.url,
          {
            id: this.basemapDetails1.id,
            attribution: this.basemapDetails1.attribution,
            maxZoom: this.basemapDetails1.maxZoom,
          },
      );
      this.config.basemapLayer = basemapLayer;
      this.activeBasemapName = this.basemapDetails1.name;
      basemapLayer.addTo(this.map);

      this.activeBasemapThumbnail = this.basemapDetails2.thumbnail;
    }
  }

  public mounted() {
    this.basemapDetails1 = this.config.BasemapLayers.Topographic;
    this.basemapDetails2 = this.config.BasemapLayers.Imagery_GeoNova;

    this.activeBasemapName = this.basemapDetails1.name;
    this.activeBasemapThumbnail = this.basemapDetails2.thumbnail;
  }
}
