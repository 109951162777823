




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as esri from 'esri-leaflet';
import $ from 'jquery';

@Component
export default class WeatherWidget extends Vue {
  public weatherReports: any = [];
  public next36HoursColumns = [
    {name: 'time', align: 'left', label: 'Time', field: 'time', style: 'width: 140px'},
    {name: 'weatherCode', align: 'left', label: 'Conditions', field: 'weatherCode', style: 'width: 20px'},
    {name: 'actualTemp', align: 'left', label: 'Temp (°C)', field: 'actualTemp', style: 'width: 80px'},
    {name: 'feelsLikeTemp', align: 'left', label: 'Feels Like (°C)', field: 'feelsLikeTemp', style: 'width: 100px'},
    {name: 'precipitation', align: 'left', label: 'Rain', field: 'precipitation', style: 'width: 20px'},
    {name: 'pop', align: 'left', label: 'P.O.P.', field: 'pop', style: 'width: 20px'},
    {name: 'windSpeed', align: 'left', label: 'Wind (km/h)', field: 'windSpeed', style: 'width: 90px'},
    {name: 'windGusts', align: 'left', label: 'Gusts (km/h)', field: 'windGusts', style: 'width: 90px'},
    {name: 'humidity', align: 'left', label: 'Humidity', field: 'humidity', style: 'width: 20px'},
  ];

  public isWeatherDetailsDisplayed = false;
  public windDirection: number = 0;
  public windSpeed: number = 0;
  public weatherCode: number = -1;
  public weatherClass: string = '1';
  public weatherDescription: string = '1';
  public weatherDescriptionStyle: string = 'width: 75px; word-break: break-word;';
  public temperature: number = 0;
  public lastUpdatedText: string = '';
  public showNext36Hours: boolean = false;

  public millisInMinute = 60000;
  public weatherIntervalInMinutes = 5;

  get config() {
    return this.$store.state.config;
  }

  get openMeteoCurrentQuery(): string {
    return this.config.OpenMeteoCurrentQuery;
  }

  get openMeteo3DaysQuery(): string {
    return this.config.OpenMeteo3DaysQuery;
  }

  get windDirectionStyle() {
    return 'transform: rotate(' + String(this.windDirection) + 'deg)';
  }

  get windSpeedDisplay() {
    return String(this.windSpeed) + ' KM/H';
  }

  get weatherInterval() {
    return this.millisInMinute * this.weatherIntervalInMinutes;
  }

  public getWeatherDetailsFromCode(code: number) {
    const dictionary: any = {
      0:  ['Clear Sky', 'fa-regular fa-sun', '1'],
      1:  ['Mainly Clear', 'fa-solid fa-sun-cloud', '1'],
      2:  ['Partly Cloudy', 'fa-regular fa-cloud-sun', '0.9'],
      3:  ['Overcast', 'fa-solid fa-cloud', '1'],
      45: ['Fog', 'fa-solid fa-cloud-fog', '1'],
      48: ['Depositing Rime Fog', 'fa-solid fa-cloud-fog', '0.75'],
      51: ['Light Drizzle', 'fa-solid fa-cloud-drizzle', '0.9'],
      53: ['Medium Drizzle', 'fa-solid fa-cloud-drizzle', '0.8'],
      55: ['Dense Drizzle', 'fa-solid fa-cloud-drizzle', '0.8'],
      61: ['Light Rain', 'fa-solid fa-cloud-rain', '0.9'],
      63: ['Moderate Rain', 'fa-solid fa-cloud-rain', '0.8'],
      65: ['Heavy Rain', 'fa-solid fa-cloud-rain', '0.9'],
      66: ['Light Freezing Rain', 'fa-solid fa-cloud-hail-mixed', '0.75'],
      67: ['Heavy Freezing Rain', 'fa-solid fa-cloud-hail-mixed', '0.75'],
      71: ['Light Snow', 'fa-solid fa-snowflake', '0.9'],
      73: ['Moderate Snow', 'fa-solid fa-snowflake', '0.8'],
      75: ['Heavy Snow', 'fa-solid fa-snowflake', '0.9'],
      77: ['Snow Grains', 'fa-solid fa-snowflake', '0.9'],
      80: ['Light Rain Showers', 'fa-sharp fa-light fa-cloud-showers', '0.75'],
      81: ['Moderate Rain Showers', 'fa-sharp fa-light fa-cloud-showers', '0.75'],
      82: ['Heavy Rain Showers', 'fa-sharp fa-light fa-cloud-showers', '0.75'],
      85: ['Light Snow Showers', 'fa-solid fa-snowflake', '0.75'],
      86: ['Heavy Snow Showers', 'fa-solid fa-snowflake', '0.75'],
      95: ['Thunderstorm', 'fa-solid fa-cloud-bolt', '0.8'],
    };

    return dictionary[code];
  }

  public degToCompass(direction: number ) {
    const val = Math.round((direction / 22.5) + 0.5);
    const arr = ['N', 'NNE', 'NE', 'ENE' , 'E' , 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    return arr[(val % 16)];
  }

  public queryOnSite() {
    const windSpeedAccesser = 'WIND_SPEED';
    const windDirectAccesser = 'WIND_DIRECT';
    const dateAccesser = 'OBS_DATETIME';
    const tempAccesser = 'TEMP';
    const data = new Promise((resolve) => {
      const onSiteQuery = esri.query({ url: this.config.WeatherLayer.url });

      if (this.config.WeatherLayer.definitionExpression !== undefined) {
        onSiteQuery.where(this.config.WeatherLayer.definitionExpression).run((error, featureCollection) => {
          if (error === undefined && featureCollection.features.length === 1) {
            const feature = featureCollection.features[0];
            const featureProperties = feature.properties;
            const returnData = {
              windSpeed:  featureProperties[windSpeedAccesser],
              windDir: featureProperties[windDirectAccesser],
              date: new Date(featureProperties[dateAccesser]),
              temp: (featureProperties[tempAccesser] - 32) * 5 / 9,
            };
            resolve(returnData);
          }
        });
      }
    });

    return data;
  }

  public queryOpenMeteoData() {
    const data = new Promise((resolve) => {
      $.ajax(this.openMeteoCurrentQuery).then((response) => {
        const returnData = {
          windSpeed: response.current_weather.windspeed,
          windDir: response.current_weather.winddirection,
          date: new Date(response.current_weather.time + '+00:00'),
          temp: response.current_weather.temperature,
          weatherCode: response.current_weather.weathercode,
        };
        resolve(returnData);
      });
    });

    return data;
  }

  public async generateWeatherDetails() {
    const onSiteData: any = await this.queryOnSite();
    const openMeteoCurrentData: any = await this.queryOpenMeteoData();

    this.isWeatherDetailsDisplayed = true;

    if (onSiteData === null && openMeteoCurrentData !== null) {
      this.windSpeed = openMeteoCurrentData.windSpeed;
      this.windDirection = openMeteoCurrentData.windDir;
      this.temperature = Math.round(openMeteoCurrentData.temp);
      this.lastUpdatedText = this.createDateString(openMeteoCurrentData.date);
    } else if (openMeteoCurrentData === null && onSiteData !== null) {
      this.windSpeed = onSiteData.windSpeed;
      this.windDirection = onSiteData.windDir;
      this.temperature = Math.round(onSiteData.temp);
      this.lastUpdatedText = this.createDateString(onSiteData.date);
    } else if (onSiteData !== null && openMeteoCurrentData !== null) {
      const onSiteDate = onSiteData.date;
      const currentTime = new Date();
      const hoursDiff = this.hoursBetweenDates(currentTime, onSiteDate);

      if (hoursDiff < 4) {
        this.windSpeed = onSiteData.windSpeed;
        this.windDirection = onSiteData.windDir;
        this.temperature = Math.round(onSiteData.temp);
        this.lastUpdatedText = this.createDateString(onSiteData.date);
      } else {
        this.windSpeed = openMeteoCurrentData.windSpeed;
        this.windDirection = openMeteoCurrentData.windDir;
        this.temperature = Math.round(openMeteoCurrentData.temp);
        this.lastUpdatedText = this.createDateString(openMeteoCurrentData.date);
      }
    } else {
      this.isWeatherDetailsDisplayed = false;
    }

    if (openMeteoCurrentData !== null) {
      this.weatherCode = openMeteoCurrentData.weatherCode;
      const weatherSummary = this.getWeatherDetailsFromCode(this.weatherCode);
      this.weatherDescription = weatherSummary[0];
      this.weatherClass = weatherSummary[1];
      this.weatherDescriptionStyle = 'width: 75px; word-break: break-word; font-size:' + weatherSummary[2] + 'em;';
    } else {
      this.weatherCode = -1;
    }
  }

  public createDateString(date: Date) {
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    if (ampm === 'PM' && hours > 12) {
      hours = hours - 12;
    }
    const dateString = monthName + ' ' + day + ', ' + hours + ':' + minutes + ' ' + ampm;

    return dateString;
  }

  public onToggleNext36HoursDialog() {
    this.showNext36Hours = !this.showNext36Hours;

    if (this.showNext36Hours) {
      $.ajax(this.openMeteo3DaysQuery).then((response) => {
        const hourly = response.hourly;

        if (hourly.apparent_temperature.length === 72 &&
            hourly.precipitation.length === 72 &&
            hourly.precipitation_probability.length === 72 &&
            hourly.relativehumidity_2m.length === 72 &&
            hourly.temperature_2m.length === 72 &&
            hourly.time.length === 72 &&
            hourly.weathercode.length === 72 &&
            hourly.winddirection_10m.length === 72 &&
            hourly.windgusts_10m.length === 72 &&
            hourly.windspeed_10m.length === 72) {
          const currentTimeFormat = this.createTFormatDateString();
          const startIndex = hourly.time.indexOf(currentTimeFormat);
          const lastIndex = startIndex + 36;

          this.weatherReports = [];
          for (let i = startIndex; i < lastIndex; i++) {
            const weatherReport = {
              time: this.createDateString(new Date(hourly.time[i])),
              actualTemp: hourly.temperature_2m[i],
              feelsLikeTemp: hourly.apparent_temperature[i],
              weatherCode: this.getWeatherDetailsFromCode(hourly.weathercode[startIndex])[0],
              pop: hourly.precipitation_probability[i] + '%',
              precipitation: hourly.precipitation[i] + 'mm',
              windSpeed: hourly.windspeed_10m[i] + ' ' + this.degToCompass(hourly.winddirection_10m[i]),
              windGusts: hourly.windgusts_10m[i],
              humidity: hourly.relativehumidity_2m[i] + '%',
            };
            this.weatherReports.push(weatherReport);
          }
        }
      });
    }
  }

  public createTFormatDateString() {
    const options: any = {
      timeZone: 'America/Halifax',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const date = new Date().toLocaleDateString('en-US', options);
    const currentDate = new Date(date);

    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const year = currentDate.getFullYear();

    const dateString = year + '-' + month + '-' + day + 'T' + hours + ':00';
    return dateString;
  }

  public hoursBetweenDates(date2: Date, date1: Date) {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  public mounted() {
    this.generateWeatherDetails();

    const windCreationFunction = () => { this.generateWeatherDetails(); };
    setInterval(windCreationFunction, this.weatherInterval);
  }
}
