export interface IPortage {
    Name: string;
    Description: string;
    Distance: number;
    Lat: number;
    Lng: number;
}

export class Portage implements IPortage {
    public Name: string;
    public Description: string;
    public Distance: number;
    public Lat: number;
    public Lng: number;

    constructor(portageObject: any, latlng: L.LatLng) {
        this.Name = portageObject.properties.Name;
        this.Description = portageObject.properties.Description;
        this.Distance = portageObject.properties.Distance;
        this.Lat = latlng.lat;
        this.Lng = latlng.lng;
    }
}
