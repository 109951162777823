export interface ITrail {
    TrailName: string;
    Distance: string;
    Lat: number;
    Lng: number;
}

export class Trail implements ITrail {
    public TrailName: string;
    public Distance: string;
    public Lat: number;
    public Lng: number;

    constructor(trailObject: any, latlng: L.LatLng) {
        this.TrailName = trailObject.properties.TrailName;
        this.Distance = trailObject.properties.Distance;
        this.Lat = latlng.lat;
        this.Lng = latlng.lng;
    }
}
