















































import { Component, Prop, Vue } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import L, { geoJSON } from 'leaflet';
import * as esri from 'esri-leaflet';
import WeatherWidget from './WeatherWidget.vue';
import BasemapToggleWidget from './BasemapToggleWidget.vue';
import MapDevLogo from './MapDevLogo.vue';
import SearchManager from './SearchManager.vue';
import SplashPopup from './SplashPopup.vue';
import { Campsite } from '../models/Campsite';
import { Cabin } from '../models/Cabin';
import { Oasis } from '../models/Oasis';
import { Yurt } from '../models/Yurt';
import { Otentik } from '../models/Otentik';
import { FrontCountryCampsite } from '../models/FrontCountryCampsite';
import { Section } from '../models/Section';
import { Trail } from '@/models/Trail';
import { Portage } from '@/models/Portage';
// @ts-ignore
import LightBox from 'vue-image-lightbox';
import MeasureTool from '@/components/MeasureTool.vue';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import $ from 'jquery';

@Component({
  components: {
    WeatherWidget,
    BasemapToggleWidget,
    MapDevLogo,
    LightBox,
    SearchManager,
    MeasureTool,
    SplashPopup,
  },
})
export default class LeafletMap extends Vue {
  public widthThresholdValue = 768;
  public heightThresholdValue = 415;

  public IsLightBoxOn: boolean = false;

  public backcountrySitesLayer: L.GeoJSON | null = null;

  public cabinsLayer: any | null = null;
  public frontcountrySitesLayer: any | null = null;
  public oasisLayer: any | null = null;
  public oTENTikLayer: any | null = null;
  public yurtLayer: any | null = null;
  public sectionLayer: any | null = null;
  public facilitiesLayer: any | null = null;

  public portagesLayer: L.Layer | null = null;
  public kejiTrailsLayer: L.Layer | null = null;

  get map(): L.Map {
    return this.$store.state.map;
  }

  get config() {
    return this.$store.state.config;
  }

  get isMobile(): boolean {
    return (this.$q.screen.width <= this.widthThresholdValue) || (this.$q.screen.height <= this.heightThresholdValue);
  }

  get imagePrefix(): string {
    return this.config.LayoutImagePrefix;
  }

  get campsiteMedia() {
    const media: any = [];
    if (this.$store.state.layers.selectedCampsite) {
      if (this.$store.state.layers.selectedCampsite.LayoutImage !== null) {
        const slideURL = this.imagePrefix + this.$store.state.layers.selectedCampsite.LayoutImage;
        media.push({
          thumb: slideURL,
          src: slideURL,
        });
      }
    }

    return media;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'showLightBox') {
      this.onShowLightBox();
    }
  });

  public async createMap() {
    const lMapOptions: L.MapOptions = {
      zoomControl: false,
      minZoom: this.config.mapDefault.minZoom,
      maxZoom: this.config.mapDefault.maxZoom,
      maxBounds: this.config.mapDefault.maxBounds,
    };

    const lMap: L.Map = L.map('map', lMapOptions);
    lMap.addControl(L.control.zoom({position: 'topleft' }));
    L.DomUtil.addClass(lMap.getContainer(), 'default-cursor-enabled');

    lMap.setView(
      [this.config.mapDefault.lat, this.config.mapDefault.long],
      this.isMobile ? this.config.mapDefault.initialZoomMobile : this.config.mapDefault.initialZoomDesktop,
    );

    const basemapLayer = L.tileLayer(
      this.config.BasemapLayers.Topographic.url,
      {
        maxZoom: 18,
        attribution: this.config.BasemapLayers.Topographic.attribution,
        id: this.config.BasemapLayers.Topographic.id,
        tileSize: 512,
        zoomOffset: -1,
      },
    ).addTo(lMap);
    this.config.basemapLayer = basemapLayer;

    this.$store.dispatch('setMap', lMap);

    let geoJSONData = await $.getJSON(this.config.PortagesLayer.url);
    this.portagesLayer = L.geoJSON(geoJSONData, {
      style: () => {
        return { color: '#c8a87e', weight: 2 };
      },
      onEachFeature: (feature, layer) => {
        const midPoint = L.latLng(feature.properties.MidPointLat, feature.properties.MidPointLong);
        const icon = L.divIcon({
            className: 'portageLabel',
            html: '<div><span>' + feature.properties.Name + '</span></div>',
          });

        const marker = L.marker(midPoint, { icon}).addTo(this.map);
        marker.on('click', () => { this.onPortageLabelClick(feature); });
      },
    });

    this.portagesLayer.on('click', this.onPortageFeatureClick);

    geoJSONData = await $.getJSON(this.config.BackcountrySitesLayer.url);
    this.backcountrySitesLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (feature: any, latlng: any) => {
        return L.marker(latlng, {
          icon: L.divIcon({
            iconAnchor: [14, 15],
            iconSize: [28, 28],
            className: 'iconCampsite',
            html: `<div class='lblCampsiteMarker'>` + feature.properties.Name + `</div>`,
          }),
        });
      },
    });

    this.backcountrySitesLayer.on('click', this.onBackCountrySiteFeatureClick);

    const cabinIcon =  L.icon({
      iconUrl: this.config.CabinsLayer.iconUrl,
      iconSize: [20, 20],
    });

    geoJSONData = await $.getJSON(this.config.CabinsLayer.url);
    this.cabinsLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        return L.marker(latlng, {
          icon: cabinIcon,
        });
      },
    });

    // @ts-ignore
    this.cabinsLayer.visibility = false;

    // @ts-ignore
    this.cabinsLayer.setStyle({display: false});

    this.cabinsLayer.on('click', this.onCabinFeatureClick);

    const frontCountryIcon =  L.icon({
      iconUrl: this.config.FrontcountrySitesLayer.iconUrl,
      iconSize: [30, 30],
    });

    geoJSONData = await $.getJSON(this.config.FrontcountrySitesLayer.url);
    this.frontcountrySitesLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        return L.marker(latlng, {
          icon: frontCountryIcon,
        });
      },
    });
    this.frontcountrySitesLayer.visibility = false;

    const frontCountryElectricIcons: any = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        const properties = geojson.properties;
        const hasElectrical = properties.Electrical_Service;

        let icon = null;
        if (hasElectrical === 1) {
          icon = L.divIcon({
            className: 'electricLabel',
            html: '<div><i class="fa-solid fa-bolt"/></div>',
          });
        } else {
          icon = L.divIcon({className: 'blank', html: ''});
        }

        return L.marker(latlng, { icon });
      },
    });
    frontCountryElectricIcons.visibility = false;

    const frontCountryAccessibilityIcons: any = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        const properties = geojson.properties;
        const isAccessible = properties.Accessible;

        let icon = null;
        if (isAccessible === 1) {
          icon = L.divIcon({
            className: 'accessibleLabel',
            html: '<div><i class="fa-solid fa-wheelchair"></i></div>',
          });
        } else {
          icon = L.divIcon({className: 'blank', html: ''});
        }

        return L.marker(latlng, { icon });
      },
    });
    frontCountryAccessibilityIcons.visibility = false;

    const frontCountryTextLayer: any = L.geoJSON(geoJSONData, {
      pointToLayer: (feature: any, latlng: any) => {
        return L.marker(latlng, {
          icon: L.divIcon({
            iconAnchor: [0, -12],
            iconSize: [30, 10],
            className: 'labelNoBackground',
            html: `<div class="frontCountryLabel">` + feature.properties.SiteName + `</div>`,
          }),
        });
      },
    });
    frontCountryTextLayer.visibility = false;

    frontCountryTextLayer.on('click', this.onFrontCountryCampsiteFeatureClick);
    frontCountryElectricIcons.on('click', this.onFrontCountryCampsiteFeatureClick);
    frontCountryAccessibilityIcons.on('click', this.onFrontCountryCampsiteFeatureClick);
    this.frontcountrySitesLayer.on('click', this.onFrontCountryCampsiteFeatureClick);

    const oasisIcon =  L.icon({
      iconUrl: this.config.OasisLayer.iconUrl,
      iconSize: [20, 20],
      className: 'oasisIcon',
    });

    geoJSONData = await $.getJSON(this.config.OasisLayer.url);
    this.oasisLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        return L.marker(latlng, {
          icon: oasisIcon,
        });
      },
    });

    this.oasisLayer.visibility = false;

    this.oasisLayer.on('click', this.onOasisFeatureClick);

    const otentikIcon =  L.icon({
      iconUrl: this.config.oTENTikLayer.iconUrl,
      iconSize: [20, 20],
    });

    geoJSONData = await $.getJSON(this.config.oTENTikLayer.url);
    this.oTENTikLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        return L.marker(latlng, {
          icon: otentikIcon,
        });
      },
    });

    this.oTENTikLayer.visibility = false;

    geoJSONData = await $.getJSON(this.config.oTENTikLayer.url);
    const oTENTikAccessibilityIcons: any = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        const properties = geojson.properties;
        const isAccessible = properties.Accessible;

        let icon = null;
        if (isAccessible === 1) {
          icon = L.divIcon({
            className: 'accessibleLabel',
            html: '<div><i class="fa-solid fa-wheelchair"></i></div>',
          });
        } else {
          icon = L.divIcon({className: 'blank', html: ''});
        }

        return L.marker(latlng, { icon });
      },
    });

    oTENTikAccessibilityIcons.visibility = false;

    oTENTikAccessibilityIcons.on('click', this.onOtentikFeatureClick);
    this.oTENTikLayer.on('click', this.onOtentikFeatureClick);

    const yurtIcon =  L.icon({
      iconUrl: this.config.YurtLayer.iconUrl,
      iconSize: [20, 20],
    });

    geoJSONData = await $.getJSON(this.config.YurtLayer.url);
    this.yurtLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        return L.marker(latlng, {
          icon: yurtIcon,
        });
      },
    });

    this.yurtLayer.visibility = false;
    this.yurtLayer.on('click', this.onYurtFeatureClick);


    geoJSONData = await $.getJSON(this.config.SectionLayer.url);
    this.sectionLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        const sectionIcon =  L.icon({
          iconUrl: this.config.SectionLayer.iconUrl,
          iconSize: [20, 20],
          className: 'sectionIcon',
        });

        return L.marker(latlng, {
          icon: sectionIcon,
        });
      },
    });
    this.sectionLayer.visibility = true;

    const sectionTextLayer: any = L.geoJSON(geoJSONData, {
      pointToLayer: (geojson, latlng) => {
        const title = geojson.properties.Title;
        const overview = geojson.properties.Overview;

        let anchorY = 0;

        switch (title) {
          case 'Slapfoot Loop':
            anchorY = 20;
            break;
          case 'oTENTik Village':
            anchorY = 20;
            break;
          case 'Jakes Landing':
            anchorY = 25;
            break;
          default:
            anchorY = 40;
            break;
        }

        const sectionDivIcon = L.divIcon({
          className: 'sectionText',
          iconAnchor: [30, anchorY],
          html: '<span>' + overview + '</span>',
        });

        return L.marker(latlng, {
          icon: sectionDivIcon,
        });
      },
    });
    sectionTextLayer.visibility = false;

    geoJSONData = await $.getJSON(this.config.FacilitiesLayer.url);
    this.facilitiesLayer = L.geoJSON(geoJSONData, {
      pointToLayer: (geoJson, latLng) => {
        const facilityType = geoJson.properties.FacilityType;
        let iconUrl = '';

        switch (facilityType) {
          case 0:
            iconUrl = this.config.FacilitiesLayer.boatLaunchIconURL;
            break;
          case 1:
            iconUrl = this.config.FacilitiesLayer.drinkingWaterIconURL;
            break;
          case 2:
            iconUrl = this.config.FacilitiesLayer.dumpingStationIconURL;
            break;
          case 3:
            iconUrl = this.config.FacilitiesLayer.outhouseIconURL;
            break;
          case 4:
            iconUrl = this.config.FacilitiesLayer.picnicDayUseAreaIconURL;
            break;
          case 5:
            iconUrl = this.config.FacilitiesLayer.playgroundIconURL;
            break;
          case 6:
            iconUrl = this.config.FacilitiesLayer.swimmingAreaIconURL;
            break;
          case 7:
            iconUrl = this.config.FacilitiesLayer.visitorCentreIconURL;
            break;
          case 8:
            iconUrl = this.config.FacilitiesLayer.wardenCabinIconURL;
            break;
          case 9:
            iconUrl = this.config.FacilitiesLayer.washroomIconURL;
            break;
          default:
            break;
        }

        const icon = L.icon({
          iconUrl,
          iconSize: [20, 20],
          className: 'facilityIcon',
        });

        return L.marker(latLng, { icon });
      },
    });

    this.facilitiesLayer.visibility = false;

    $.getJSON(this.config.ParkBoundaryLayer.url, (data: any) => {
      L.geoJSON(data, {
        style: () => {
          return { color: '#00a769', weight: 2, fill: false };
        },
      }).addTo(lMap);
    });

    geoJSONData = await $.getJSON(this.config.KejiTrails.url);
    this.kejiTrailsLayer = L.geoJSON(geoJSONData, {
      style: () => {
        return { color: '#9c2d05', weight: 2 };
      },
    });

    this.kejiTrailsLayer.on('click', this.onTrailsFeatureClick);

    lMap.on('zoomend', (e) => {
      const currentZoom = lMap.getZoom();

      // Cabins
      if (currentZoom >= this.config.CabinsLayer.minZoom && !this.cabinsLayer.visibility) {
        lMap.addLayer(this.cabinsLayer);
        this.cabinsLayer.visibility = true;
      } else if (currentZoom < this.config.CabinsLayer.minZoom && this.cabinsLayer.visibility) {
        lMap.removeLayer(this.cabinsLayer);
        this.cabinsLayer.visibility = false;
      }

      // Facilities
      if (currentZoom >= this.config.FacilitiesLayer.minZoom && !this.facilitiesLayer.visibility) {
        lMap.addLayer(this.facilitiesLayer);
        this.facilitiesLayer.visibility = true;
      } else if (currentZoom < this.config.FacilitiesLayer.minZoom && this.facilitiesLayer.visibility) {
        lMap.removeLayer(this.facilitiesLayer);
        this.facilitiesLayer.visibility = false;
      }

      // Oasis
      if (currentZoom >= this.config.OasisLayer.minZoom && !this.oasisLayer.visibility) {
        lMap.addLayer(this.oasisLayer);
        this.oasisLayer.visibility = true;
      } else if (currentZoom < this.config.OasisLayer.minZoom && this.oasisLayer.visibility) {
        lMap.removeLayer(this.oasisLayer);
        this.oasisLayer.visibility = false;
      }

      // oTentik
      if (currentZoom >= this.config.oTENTikLayer.minZoom && !this.oTENTikLayer.visibility) {
        lMap.addLayer(this.oTENTikLayer);
        this.oTENTikLayer.visibility = true;
      } else if (currentZoom < this.config.oTENTikLayer.minZoom && this.oTENTikLayer.visibility) {
        lMap.removeLayer(this.oTENTikLayer);
        this.oTENTikLayer.visibility = false;
      }

      // oTentik accessibility
      if (currentZoom >= 17 && !oTENTikAccessibilityIcons.visibility) {
        lMap.addLayer(oTENTikAccessibilityIcons);
        oTENTikAccessibilityIcons.visibility = true;
      } else if (currentZoom < 17 && oTENTikAccessibilityIcons.visibility) {
        lMap.removeLayer(oTENTikAccessibilityIcons);
        oTENTikAccessibilityIcons.visibility = false;
      }

      // Yurt
      if (currentZoom >= this.config.YurtLayer.minZoom && !this.yurtLayer.visibility) {
        lMap.addLayer(this.yurtLayer);
        this.yurtLayer.visibility = true;
      } else if (currentZoom < this.config.YurtLayer.minZoom && this.yurtLayer.visibility) {
        lMap.removeLayer(this.yurtLayer);
        this.yurtLayer.visibility = false;
      }

      // Front country
      if (currentZoom >= this.config.FrontcountrySitesLayer.minZoom && !this.frontcountrySitesLayer.visibility) {
        lMap.addLayer(this.frontcountrySitesLayer);
        this.frontcountrySitesLayer.visibility = true;
      } else if (currentZoom < this.config.FrontcountrySitesLayer.minZoom && this.frontcountrySitesLayer.visibility) {
        lMap.removeLayer(this.frontcountrySitesLayer);
        this.frontcountrySitesLayer.visibility = false;
      }

      // Front country electricity
      if (currentZoom >= 17 && !frontCountryElectricIcons.visibility) {
        lMap.addLayer(frontCountryElectricIcons);
        frontCountryElectricIcons.visibility = true;
      } else if (currentZoom < 17 && frontCountryElectricIcons.visibility) {
        lMap.removeLayer(frontCountryElectricIcons);
        frontCountryElectricIcons.visibility = false;
      }

      // Front country accessibility
      if (currentZoom >= 17 && !frontCountryAccessibilityIcons.visibility) {
        lMap.addLayer(frontCountryAccessibilityIcons);
        frontCountryAccessibilityIcons.visibility = true;
      } else if (currentZoom < 17 && frontCountryAccessibilityIcons.visibility) {
        lMap.removeLayer(frontCountryAccessibilityIcons);
        frontCountryAccessibilityIcons.visibility = false;
      }

      // Front country text
      if (currentZoom >= 17 && !frontCountryTextLayer.visibility) {
        lMap.addLayer(frontCountryTextLayer);
        frontCountryTextLayer.visibility = true;
      } else if (currentZoom < 17 && frontCountryTextLayer.visibility) {
        lMap.removeLayer(frontCountryTextLayer);
        frontCountryTextLayer.visibility = false;
      }

      // Section
      if (currentZoom >= this.config.SectionLayer.minZoom &&
          currentZoom <= this.config.SectionLayer.maxZoom &&
          !this.sectionLayer.visibility) {
        lMap.addLayer(this.sectionLayer);
        this.sectionLayer.visibility = true;
      } else if (!(currentZoom >= this.config.SectionLayer.minZoom &&
                   currentZoom <= this.config.SectionLayer.maxZoom) &&
                   this.sectionLayer.visibility) {
        lMap.removeLayer(this.sectionLayer);
        this.sectionLayer.visibility = false;
      }

      // Section Text
      if (currentZoom >= this.config.SectionTextLayer.minZoom &&
          currentZoom <= this.config.SectionTextLayer.maxZoom &&
          !sectionTextLayer.visibility) {
        lMap.addLayer(sectionTextLayer);
        sectionTextLayer.visibility = true;
      } else if (!(currentZoom >= this.config.SectionTextLayer.minZoom &&
                   currentZoom <= this.config.SectionTextLayer.maxZoom) &&
                   sectionTextLayer.visibility) {
        lMap.removeLayer(sectionTextLayer);
        sectionTextLayer.visibility = false;
      }
    });

    lMap.addLayer(this.backcountrySitesLayer);
    lMap.addLayer(this.sectionLayer);
    lMap.addLayer(this.portagesLayer);
    lMap.addLayer(this.kejiTrailsLayer);
  }

  public mounted() {
    this.createMap();
    this.setupWindowHeight();
  }

  public setupWindowHeight() {
    const map = document.getElementById('map') as HTMLElement;
    map.style.height = window.innerHeight + 'px';

    window.addEventListener('resize', () => {
      map.style.height = window.innerHeight + 'px';
    });
  }

  public onBackCountrySiteFeatureClick(event: L.LeafletMouseEvent) {
    this.resetStyles();
    this.$store.dispatch(
      'changeSelectedCampsite',
      new Campsite(event.layer.feature),
    );
    this.$store.dispatch('showCampsitePopup');
  }

  public onFrontCountryCampsiteFeatureClick(event: L.LeafletMouseEvent) {
    this.$store.dispatch(
      'changeSelectedFrontCountryCampsite',
      new FrontCountryCampsite(event.layer.feature),
    );
    this.$store.dispatch('showFrontCountryCampsitePopup');
  }

  public onCabinFeatureClick(event: L.LeafletMouseEvent) {
    this.$store.dispatch(
      'changeSelectedCabin',
      new Cabin(event.layer.feature),
    );
    this.$store.dispatch('showCabinPopup');
  }

  public onOasisFeatureClick(event: L.LeafletMouseEvent) {
    this.$store.dispatch(
      'changeSelectedOasis',
      new Oasis(event.layer.feature),
    );
    this.$store.dispatch('showOasisPopup');
  }

  public onYurtFeatureClick(event: L.LeafletMouseEvent) {
    this.$store.dispatch(
      'changeSelectedYurt',
      new Yurt(event.layer.feature),
    );
    this.$store.dispatch('showYurtPopup');
  }

public onOtentikFeatureClick(event: L.LeafletMouseEvent) {
  this.$store.dispatch(
    'changeSelectedOtentik',
    new Otentik(event.layer.feature),
  );
  this.$store.dispatch('showOtentikPopup');
}

  public onTrailsFeatureClick(event: L.LeafletMouseEvent) {
    this.resetStyles();
    this.$store.dispatch(
      'changeSelectedTrail',
      new Trail(event.layer.feature, event.latlng),
    );

    // @ts-ignore
    this.kejiTrailsLayer?.eachLayer((feature: any) => {
      if (feature.feature.properties.OBJECTID === event.layer.feature.properties.OBJECTID) {
        feature.setStyle({color: '#00FFFF'});
      }
    });
    this.$store.dispatch('showTrailPopup');
  }

  public onPortageLabelClick(portageFeature: any) {
    this.resetStyles();
    this.$store.dispatch(
      'changeSelectedPortage',
      new Portage(portageFeature, L.latLng(portageFeature.properties.MidPointLat,
                                                   portageFeature.properties.MidPointLong)),
    );

    // @ts-ignore
    this.portagesLayer?.eachLayer((feature: any) => {
      if (feature.feature.properties.OBJECTID === portageFeature.properties.OBJECTID) {
        feature.setStyle({color: '#00FFFF'});
      }
    });

    this.$store.dispatch('showPortagePopup');
  }

  public onPortageFeatureClick(event: L.LeafletMouseEvent) {
    this.resetStyles();
    this.$store.dispatch(
      'changeSelectedPortage',
      new Portage(event.layer.feature, event.latlng),
    );

    // @ts-ignore
    this.portagesLayer?.eachLayer((feature: any) => {
      if (feature.feature.properties.OBJECTID === event.layer.feature.properties.OBJECTID) {
        feature.setStyle({color: '#00FFFF'});
      }
    });

    this.$store.dispatch('showPortagePopup');
  }

  public resetStyles() {
    // @ts-ignore
    this.kejiTrailsLayer?.eachLayer((feature: any) => { feature.setStyle({color: '#9c2d05'}); });

    // @ts-ignore
    this.portagesLayer?.eachLayer((feature: any) => { feature.setStyle({color: '#c8a87e'}); });
  }

  public onShowLightBox() {
    // @ts-ignore
    this.$refs.lightbox.showImage(0);
    this.IsLightBoxOn = true;
  }

  public onClose() {
    this.IsLightBoxOn = false;
  }

  public showSplash() {
    this.$store.dispatch('showSplashNoCheckBox');
  }
}
