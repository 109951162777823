














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MapDevLogo extends Vue {
    get config() {
        return this.$store.state.config;
    }

    get mapDevURL() {
        return this.config.MapDevURL;
    }
}

