export interface ICabin {
    Name: string;
    SectionInfo: string;
    PetFriendly: boolean;
    Lat: number;
    Long: number;
}

export class Cabin implements ICabin {
    public Name: string;
    public SectionInfo: string;
    public PetFriendly: boolean;
    public Lat: number;
    public Long: number;

    constructor(cabinObject: any) {
        this.Name = cabinObject.properties.SiteName;
        this.SectionInfo = cabinObject.properties.SectionInfo;

        if (cabinObject.properties.PetFriendly === 0) {
            this.PetFriendly = false;
        } else {
            this.PetFriendly = true;
        }

        this.Lat = cabinObject.geometry.coordinates[1];
        this.Long = cabinObject.geometry.coordinates[0];
    }
}
