





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import L, { latLng, LatLng } from 'leaflet';
import { Portage } from '../models/Portage';

@Component
export default class PopupPortage extends Vue {
    public showPopup = false;
    public popup: L.Popup | null = null;

    public name: string = '';
    public description: string = '';
    public displayDistance: string = '';

    public subscribe = this.$store.subscribeAction((action, state) => {
        if (action.type === 'showPortagePopup') {
            this.onShowPortagePopup();
        }
    });

    get map(): L.Map {
        return this.$store.state.map;
    }

    get config() {
        return this.$store.state.config;
    }

    get selectedPortage(): Portage {
        return this.$store.state.layers.selectedPortage;
    }

    public onShowPortagePopup() {
        const portage = this.selectedPortage;

        this.showPopup = true;
        this.name = portage.Name;
        this.description = portage.Description;
        this.displayDistance = portage.Distance + ' km';

        const lLatLng = L.latLng(portage.Lat, portage.Lng);

        this.popup = L.popup({
            className: 'portagePopup',
            minWidth: 250,
            maxWidth: 250,
            offset: new L.Point(0, 5),
        })
        .setLatLng(lLatLng)
        .setContent(this.$el as HTMLElement)
        .openOn(this.map);
    }
}
